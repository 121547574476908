import { IPagination } from '../../core/models/api/pagination/pagination.model';
import { CityTaxSummary } from '../../models';

export interface State {
  isLoading?: boolean;
  error?: any;
  data: Record<number, CityTaxSummary>;
  pagination: IPagination;
}

export const initialState: State = {
  isLoading: false,
  error: null,
  data: null,
  pagination: null,
};
