import { createAction, props } from '@ngrx/store';

import { CityTaxSummary, CityTaxSummaryLoadRequest } from '../../models';

export const loadRequest = createAction(
  '[City Tax Summary] Load Request',
  props<{
    request: CityTaxSummaryLoadRequest;
  }>(),
);
export const loadSuccess = createAction(
  '[City Tax Summary] Load Success',
  props<{ data: Record<number, CityTaxSummary> }>(),
);
export const loadFailure = createAction(
  '[City Tax Summary] Load Failure',
  props<{ error: any }>(),
);

export const exportRequest = createAction(
  '[City Tax Summary] Export Request',
  props<{
    request: CityTaxSummaryLoadRequest;
    filename?: string;
    expands?: Record<number, Record<string, boolean>>;
  }>(),
);
export const exportSuccess = createAction('[City Tax Summary] Export Success');
export const exportFailure = createAction(
  '[City Tax Summary] Export Failure',
  props<{ error: any }>(),
);

export const resetState = createAction('[City Tax Summary] Reset State');
