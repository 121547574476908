import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { CityTaxSummaryStoreEffects } from './effects';
import { cityTaxSummaryReducer } from './reducer';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature('cityTaxSummary', cityTaxSummaryReducer),
    EffectsModule.forFeature([CityTaxSummaryStoreEffects]),
  ],
})
export class CityTaxSummaryStoreModule {}
