import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { IResponseSuccess } from '../../core/models/response-sucess.model';
import { ErrorHandlerService } from '../../core/services/error-handler.service';
import { CityTaxSummary } from '../../models';
import { CityTaxSummaryService } from '../../services';

import * as fromActions from './actions';

@Injectable()
export class CityTaxSummaryStoreEffects {
  constructor(
    private actions$: Actions,
    private dataService: CityTaxSummaryService,
    private errorHandler: ErrorHandlerService,
  ) {}

  load$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadRequest),
      switchMap(({ request }) =>
        this.dataService.load(request).pipe(
          map(({ data }: IResponseSuccess<Record<number, CityTaxSummary>>) =>
            fromActions.loadSuccess({ data }),
          ),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.loadFailure(error));
          }),
        ),
      ),
    ),
  );

  export$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.exportRequest),
      switchMap(({ request, filename, expands }) =>
        this.dataService.export(request, filename, expands).pipe(
          map(() => fromActions.exportSuccess()),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.exportFailure(error));
          }),
        ),
      ),
    ),
  );
}
