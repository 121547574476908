import {
  createFeatureSelector,
  createSelector,
  MemoizedSelector,
} from '@ngrx/store';

import { State } from './state';

export const selectState: MemoizedSelector<
  object,
  State
> = createFeatureSelector<State>('cityTaxSummary');

export const selectError = createSelector(selectState, (state) => state.error);

export const selectIsLoading = createSelector(
  selectState,
  (state) => state.isLoading,
);

export const selectData = createSelector(selectState, (state) => state.data);
